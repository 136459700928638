import React, { useState, useEffect } from "react";
import axios from "axios";
import style from "./user.module.css";
import { QRCode } from "react-qrcode-logo";
import logo from "../../logos/lookmeuplogo.png";
import LoadingImage from "../../components/LoadingImage";
import { useParams, useNavigate } from "react-router-dom";
import {
  MDBCard,
  MDBCardBody,
  MDBCardHeader,
  MDBCardFooter,
  MDBContainer
 } from "mdb-react-ui-kit";

const baseUrl = "https://lookmeup.io/api/user/user";

const User = (props) => {
  const navigate = useNavigate();
  const [user, setUser] = useState("");
  const [links, setLinks] = useState([]);
  const [phones, setPhones] = useState([]);
  const [loading, setLoading] = useState(false);

  const { id: userId } = useParams();
  useEffect(() => {
    setLoading(true);
    // setTimeout(() => {
    //   setLoading(false);
    // }, 500);
    console.log(userId);
    loadUserData(userId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userId]);

  // -----------------------loading user info--------------------------------------------
  const loadUserData = async (userId) => {
    
    await axios
      .get(`${baseUrl}/${userId}`)
      .then((userData) => {
        if (userData.data.message) {
          navigate("/usernotfound");
        } else {
          setUser(userData.data);
          setLinks(userData.data.links);
          setPhones(userData.data.phones);
          setLoading(false);
        }
      })
      .catch((err) => console.log(err));
  };

  if (loading) {
    return <LoadingImage />;
  }
  return (
<>
    <MDBContainer className="p-4   mb-5  user">
      <MDBCard alignment="center" className={style.card}>
        <MDBCardHeader>
          <div>
            <img className={style.image} src={user.image} alt="" />
          </div>
          <div className="h1 mt-2">
            <p>
              {user.firstname} {user.lastname}
            </p>
          </div>

          <div className="h3 mt-2">
            <p>{user.occupation}</p>
          </div>
        </MDBCardHeader>
        <MDBCardBody>
          <div className="h5 p-3 d-flex flex-column align-items-center justify-content-center">
            <ul>
              {phones.map(({ phone, title, _id }) => (
                <li key={_id}>
                  <span>{title}: </span>
                  <span>{phone}</span>
                </li>
              ))}
            </ul>
          </div>
          <div className="d-flex flex-column align-items-center justify-content-center">
            <ul>
              {links.map(({ link, title, _id }) => (
                <li key={_id}>
                  <div>
                    <a className={style.socialMedia} href={link}>
                      <span>{title}</span>
                    </a>
                  </div>
                  <div></div>
                </li>
              ))}
            </ul>
          </div>
        </MDBCardBody>
        <MDBCardFooter className="text-muted">
          <div className={style.qrCode}>
            <QRCode
              value={`https://lookmeup.io/user/${user._id}`}
              qrStyle="dots"
              size={240}
              logoImage={logo}
              logoWidth={40}
              fgColor="black"
              quietZone={7}
              // bgColor="transparent"
              removeQrCodeBehindLogo="true"
              ecLevel="H"
              eyeRadius={15}
              eyeColor="black"
            />
          </div>
        </MDBCardFooter>
      </MDBCard>
      </MDBContainer>
</>
  );
};

export default User;
