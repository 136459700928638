import React from "react";
import {
  MDBNavbar,
  MDBContainer,
  MDBNavbarBrand
} from "mdb-react-ui-kit";
const Nav = () => {

  return (
    <MDBNavbar  light bgColor='light'  className="navbar" >
        <MDBContainer fluid>
          <MDBNavbarBrand href='/login'>Look Me Up</MDBNavbarBrand>
        </MDBContainer>
      </MDBNavbar>
  );
};

export default Nav;
