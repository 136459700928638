import React, { useState } from "react";
import axios from "axios";
import style from "./login.module.css";
import Auth from "../../utils/auth";
import { useNavigate, Link } from "react-router-dom";
import { useGoogleLogin } from "@react-oauth/google";
import { MDBContainer } from "mdb-react-ui-kit";

const baseUrl = "https://lookmeup.io/api/user";

const Login = (props) => {
  const navigate = useNavigate();
  const [error, setError] = useState("");

  const getUserEmail = useGoogleLogin({
    onSuccess: async (respose) => {
      try {
        const res = await axios.get(
          "https://www.googleapis.com/oauth2/v3/userinfo",
          {
            headers: {
              Authorization: `Bearer ${respose.access_token}`,
              Accept: "application/json",
            },
          }
        );
        const email = res.data.email;
        const password = res.data.sub;
        login(email, password);
      } catch (err) {
        console.log(err);
      }
    },
  });

  const login = async (userEmail, userPass) => {
    await axios
      .post(`${baseUrl}/login`, { email: userEmail, password: userPass })
      .then((userData) => {
        // console.log(userData.data);
        if (userData.data.message) {
          setError(userData.data.message);
        } else {
          Auth.login(userData.data.token);
          navigate("/profile");
        }
      })
      .catch((err) => console.log(err));
  };

  return (
    <MDBContainer className="center">
      <div className="p-3 d-flex align-items-center justify-content-center">
        <div>
          <button
            type="button"
            className={style.googleSign}
            onClick={() => getUserEmail()}
          >
            <img src={require("../../logos/icons8-google-48.png")} alt="" />
            <span> Sign In with Google</span>
          </button>
          <div className={style.OrSignup}>
            <span>Or </span>
            <Link to="/signup"> Sign Up</Link>
          </div>
          {error && <div className={style.error}>{error}</div>}
        </div>
      </div>
    </MDBContainer>
  );
};

export default Login;
