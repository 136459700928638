import axios from "axios";
import Auth from "../auth";
const baseUrl = "https://lookmeup.io/api/user";
const deletLink = "deleteLink";
const add_link = "addlink";
const add_phone = "addphone";
const deletePhoneNumber = "deletephonenumber";
const updateUser = "updateUser";
const token = Auth.getToken();
class apiCalls {
  // retrieve data saved in token
  loadUserData = async (userId, access_token) => {
    console.log(userId);
    console.log(access_token);
    await axios
      .get(`${baseUrl}/${userId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
          Accept: "application/json",
        },
      })
      .then((userData) => {
        const userInfo = userData.data.str;
        return userInfo;
      })
      .catch((err) => console.log(err));
  };

  addLink = async (userId, linkInfo) => {
    await axios
      .post(
        [baseUrl, add_link, userId].join("/"),
        { title: linkInfo.title, link: linkInfo.link },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            Accept: "application/json",
          },
        }
      )
      .then((res) => {
        console.log(res.data);
      })
      .catch((err) => console.log(err));
  };

  delete_link = async (linkId, userId) => {
    await axios
      .post(
        [baseUrl, deletLink].join("/"),
        { linkId: linkId, userId: userId },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            Accept: "application/json",
          },
        }
      )
      .then((res) => {
        console.log(res.data);
      })
      .catch((err) => console.log(err));
  };

  update_user = async (userId, userInfo) => {
    await axios
      .put(
        [baseUrl, updateUser, userId].join("/"),
        {
          occupation: userInfo.occupation,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            Accept: "application/json",
          },
        }
      )
      .then((res) => {
        console.log(res.data);
        window.location.assign("/profile");
      })
      .catch((err) => console.log(err));
  };

  addPhoneNumber = async (userId, phoneNumberInfo) => {
    await axios
      .post(
        [baseUrl, add_phone, userId].join("/"),
        { title: phoneNumberInfo.title, phone: phoneNumberInfo.phone },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            Accept: "application/json",
          },
        }
      )
      .then((res) => {
        console.log(res.data);
      })
      .catch((err) => console.log(err));
  };

  delete_phone_number = async (phoneNumberId, userId) => {
    await axios
      .post(
        [baseUrl, deletePhoneNumber].join("/"),
        { phoneId: phoneNumberId, userId: userId },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            Accept: "application/json",
          },
        }
      )
      .then((res) => {
        console.log(res.data);
      })
      .catch((err) => console.log(err));
  };
}

// eslint-disable-next-line import/no-anonymous-default-export
export default new apiCalls();
