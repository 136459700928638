import React, { useState, useEffect } from "react";
import axios from "axios";
import style from "./profile.module.css";
import Auth from "../../utils/auth";
import decode from "jwt-decode";
import { QRCode } from "react-qrcode-logo";
import logo from "../../logos/lookmeuplogo.png";
import LoadingImage from "../../components/LoadingImage";
import apiCalls from "../../utils/helper/apiCalls";
import { Navigate } from "react-router-dom";
import {
  MDBInputGroup,
  MDBBtn,
  MDBInput,
  MDBCard,
  MDBCardBody,
  MDBIcon,
  MDBCardHeader,
  MDBCardFooter,
  MDBContainer
} from "mdb-react-ui-kit";

const baseUrl = "https://lookmeup.io/api/user";

const Profile = (props) => {
  const [user, setUser] = useState("");
  const [links, setLinks] = useState([]);
  const [phones, setPhones] = useState([]);

  const [loading, setLoading] = useState(false);
  const [formState, setFormState] = useState({
    title: "",
    link: "",
  });
  const [phone, setPhone] = useState({
    title: "",
    number: "",
  });
  const [occupation, setOccupation] = useState("");
  const [occ, setOcc] = useState(false);
  const [editOcc, setEditOcc] = useState(false);

  useEffect(() => {
    Auth.loggedIn();
    const token = Auth.getToken();
    const userId = decode(token);
    setLoading(true);
    loadUserData(userId.userId, token);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChange = (event) => {
    setFormState({ ...formState, [event.target.name]: event.target.value });
  };

  // -----------------------loading user info--------------------------------------------
  const loadUserData = async (userId, access_token) => {
    await axios
      .get(`${baseUrl}/${userId}`, {
        headers: {
          Authorization: `Bearer ${access_token}`,
          Accept: "application/json",
        },
      })
      .then((userData) => {
        setUser(userData.data);
        setLinks(userData.data.links);
        setPhones(userData.data.phones);
        console.log(userData.data.id);
        setLoading(false);
        if (userData.data.occupation) setOcc(true);
      })

      .catch((err) => console.log(err));
  };

  //-------------------- add social media link----------------------------------------
  const handleLink = async (event) => {
    event.preventDefault();
    const userId = user._id;
    apiCalls.addLink(userId, formState);
    setLinks([...links, formState]);
    setFormState({
      title: "",
      link: "",
    });
  };
  //-------------------- delete social media link-------------------------------------
  const deleteLink = (id) => {
    apiCalls.delete_link(id, user._id);
    setLinks(links.filter((link) => link._id !== id));
  };

  //-------------------- add and handlling occupation----------------------------------------

  const handleChangeOccupation = (event) => {
    event.preventDefault();
    setOccupation({ ...occupation, [event.target.name]: event.target.value });
  };

  const handlePhoneChange = (event) => {
    event.preventDefault();
    setPhone({ ...phone, [event.target.name]: event.target.value });
  };

  const addOccupation = () => {
    apiCalls.update_user(user._id, occupation);
    // window.location.assign("/profile");
  };
  //-------------------- add and delet phone number----------------------------------------
  const addPhone = () => {
    apiCalls.addPhoneNumber(user._id, phone);
    setPhones([...phones, phone]);
  };
  const deletePhoneNumber = (id) => {
    apiCalls.delete_phone_number(id, user._id);
    setPhones(phones.filter((phone) => phone._id !== id));
  };

  if (loading) {
    return <LoadingImage />;
  }

  return (
    <>
      {Auth.loggedIn() ? (
        <MDBContainer className="p-4  content mb-5">
        <MDBCard alignment="center">
          <MDBCardHeader>
            <div>
              <div>
                <img className={style.image} src={user.image} alt="" />
              </div>
              <div className="h1 p-2 ">
                <h1>
                  {user.firstname} {user.lastname}
                </h1>
              </div>
              {occ ? (
                <div className=" p-2 d-flex flex-row align-items-center justify-content-center">
                  <p className="h3">{user.occupation}</p>
                  <button
                    className={style.editButton}
                    onClick={() => {
                      setOcc(false);
                      setEditOcc(true);
                    }}
                  >
                    <MDBIcon fas icon="pencil-alt" size="xl" />
                  </button>
                </div>
              ) : (
                <>
                  {editOcc ? (
                    <div className="w-100 h-100 p-3 d-flex flex-row align-items-center justify-content-center">
                      <MDBInputGroup>
                        <MDBInput
                          className="form-control"
                          label="Occupation"
                          id="typeText"
                          type="text"
                          name="occupation"
                          defaultValue={user.occupation}
                          onChange={(e) => handleChangeOccupation(e)}
                        />
                        <MDBBtn
                          outline
                          onClick={() => {
                            addOccupation();
                          }}
                        >
                          <MDBIcon fas icon="check" size="xl" />
                        </MDBBtn>
                      </MDBInputGroup>
                    </div>
                  ) : (
                    <div className="d-flex flex-row align-items-center justify-content-center">
                      <MDBInputGroup>
                        <MDBInput
                          className="form-control"
                          label="Occupation"
                          placeholder="Add occupation"
                          id="typeText"
                          type="text"
                          name="occupation"
                          value={occupation.occupation}
                          onChange={(e) => {
                            handleChangeOccupation(e);
                          }}
                        />
                        <MDBBtn
                          outline
                          onClick={() => {
                            addOccupation();
                          }}
                        >
                          <MDBIcon fas icon="plus" size="xl" />
                        </MDBBtn>
                      </MDBInputGroup>
                    </div>
                  )}
                </>
              )}
            </div>
          </MDBCardHeader>
          <MDBCardBody>
            <div>
              <div className="mb-2 d-flex flex-row align-items-center justify-content-center">
                <form >
                  <MDBInputGroup >
                    <MDBInput
                      className="form-control"
                      placeholder="Example: Mobile"
                      label="Title"
                      id="typeText"
                      type="text"
                      name="title"
                      value={phone.title}
                      onChange={(e) => {
                        handlePhoneChange(e);
                      }}
                    />
                    <MDBInput
                      className="form-control"
                      placeholder="555-555-5555"
                      name="phone"
                      label="Phone number"
                      id="typePhone"
                      type="tel"
                      value={phone.phone}
                      onChange={(e) => {
                        handlePhoneChange(e);
                      }}
                    />
                    <MDBBtn
                      outline
                      onClick={() => {
                        addPhone();
                      }}
                    >
                      <MDBIcon fas icon="plus" size="xl" />
                    </MDBBtn>
                  </MDBInputGroup>
                </form>
              </div>

              <div className={style.socialMediaContainer}>
                <ul>
                  {phones.map(({ phone, title, _id }) => (
                    <li
                      className=" m-3 d-flex flex-row align-items-center  justify-content-center"
                      key={_id}
                    >
                      <div className={style.phoneNumber}>
                        <span>
                          {title}: {phone}
                        </span>
                      </div>
                      <div>
                        <button
                          className={style.socialMediaDeleteLink}
                          onClick={() => {
                            deletePhoneNumber(_id);
                          }}
                        >
                          <MDBIcon fas icon="backspace" size="lg" />
                        </button>
                      </div>
                    </li>
                  ))}
                </ul>
              </div>

              <div className="d-flex flex-row align-items-center justify-content-center">
                <form onSubmit={handleLink}>
                  <MDBInputGroup>
                    <MDBInput
                      className="form-control"
                      placeholder="Example: Youtube"
                      label="Title"
                      id="typeText"
                      type="text"
                      name="title"
                      value={formState.title}
                      onChange={(e) => {
                        handleChange(e);
                      }}
                    />
                    <MDBInput
                      className="form-control"
                      placeholder="https://www.youtube.com/"
                      label="URL input"
                      id="typeURL"
                      type="url"
                      name="link"
                      pattern="https://.*"
                      value={formState.link}
                      onChange={(e) => {
                        handleChange(e);
                        // urlChecker(e);
                      }}
                    />
                    <MDBBtn type="submit" outline>
                      <MDBIcon fas icon="plus" size="xl" />
                    </MDBBtn>
                  </MDBInputGroup>
                </form>
              </div>
            </div>
            <div className={style.socialMediaContainer}>
              <ul>
                {links.map(({ link, title, _id }) => (
                  <li
                    className=" m-3 d-flex flex-row align-items-center  justify-content-center"
                    key={_id}
                  >
                    <div>
                      <a className={style.socialMedia} href={link}>
                        <span>{title}</span>
                      </a>
                    </div>
                    <div>
                      <button
                        className={style.socialMediaDeleteLink}
                        onClick={() => {
                          deleteLink(_id);
                        }}
                      >
                        <MDBIcon fas icon="backspace" size="2x" />
                      </button>
                    </div>
                  </li>
                ))}
              </ul>
            </div>
          </MDBCardBody>
          <MDBCardFooter className="text-muted">
            <div>
            <MDBBtn color='tertiary' rippleColor='light' href={`/user/${user._id}`}>
              <QRCode
                value={`https://lookmeup.io/user/${user._id}`}
                qrStyle="dots"
                size={240}
                logoImage={logo}
                logoWidth={40}
                fgColor="black"
                quietZone={7}
                // bgColor="transparent"
                removeQrCodeBehindLogo="true"
                ecLevel="H"
                eyeRadius={15}
                eyeColor="black"
              />
              </MDBBtn>
            </div>
          </MDBCardFooter>
        </MDBCard>
        </MDBContainer>
      ) : (
        <>
          {/* need to redirect to login */}
          <Navigate to="/login" />
        </>
      )}
    </>
  );
};

export default Profile;
