import React from "react";
import { MDBFooter } from "mdb-react-ui-kit";

function Footer() {
  return (
    <MDBFooter
      bgColor="light"
      className="mt-3 fixed-bottom text-center text-lg-left"
    >
      <div className="text-center p-3">
        &copy; {new Date().getFullYear()} Copyright:{" "}
        <a className="text-dark" href="https://lookmeup.io/">
          lookmeup.io
        </a>
      </div>
    </MDBFooter>
  );
}

export default Footer;
