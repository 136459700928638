import React from "react";
import { MDBContainer } from "mdb-react-ui-kit";
const LoadingImage = (props) => {
  return (
    <MDBContainer>
      <h1>loading</h1>
    </MDBContainer>
  );
};

export default LoadingImage;
