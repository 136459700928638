import React from "react";
import { MDBContainer } from "mdb-react-ui-kit";

const Notfound = (props) => {
  return (
    <MDBContainer className="center d-flex align-items-center justify-content-center">
      <h1>USER NOT FOUND</h1>
    </MDBContainer>
  );
};

export default Notfound;
