import Footer from "./components/Footer";
import Nav from "./components/Nav";
import Login from "./pages/Login";
import Nomatch from "./pages/Nomatch";
import Notfound from "./pages/Notfound";
import Profile from "./pages/Profile";
import Signup from "./pages/Signup";
import User from "./pages/User";

import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

function App() {
  return (
    <Router>
      <Nav />
      <Routes>
        <Route exact path="/" element={<Login />} />
        <Route exact path="/login" element={<Login />} />
        <Route exact path="/signup" element={<Signup />} />
        <Route exact path="/user/:id" element={<User />} />
        <Route exact path="/profile" element={<Profile />} />
        <Route exact path="/usernofound" element={<Notfound />} />
        <Route exact path="*" element={<Nomatch />} />
      </Routes>
      <Footer />
    </Router>
  );
}

export default App;
