import React from "react";
import { MDBContainer } from "mdb-react-ui-kit";

const Nomatch = (props) => {
  return (
    <MDBContainer className="center  d-flex align-items-center justify-content-center">
      <h1>no match</h1>
    </MDBContainer>
  );
};

export default Nomatch;
