import React, { useState } from "react";
import axios from "axios";
import Auth from "../../utils/auth";
import { useGoogleLogin } from "@react-oauth/google";
import style from "./signup.module.css";
import { useNavigate, Link } from "react-router-dom";
import { MDBContainer } from 'mdb-react-ui-kit';
const baseUrl = "https://lookmeup.io/api/user";

const Signup = (props) => {
  const navigate = useNavigate();
  const [error, setError] = useState("");
  const signup = useGoogleLogin({
    onSuccess: async (respose) => {
      try {
        const res = await axios.get(
          "https://www.googleapis.com/oauth2/v3/userinfo",
          {
            headers: {
              Authorization: `Bearer ${respose.access_token}`,
            },
          }
        );

        createUser(res.data);
      } catch (err) {
        console.log(err);
      }
    },
  });

  const createUser = async (userInfo) => {
    const occupation = "";
    // console.log(userInfo);
    let user = {
      firstname: userInfo.given_name,
      lastname: userInfo.family_name,
      email: userInfo.email,
      image: userInfo.picture,
      password: userInfo.sub,
      occupation: occupation,
    };

    await axios
      .post(`${baseUrl}/signup`, user)
      .then((res) => {
        // console.log("res.data",res.data)
        if (res.data.message) {
          setError(res.data.message);
        } else {
          // console.log(res.data);
          Auth.signup(res.data.token);
          navigate("/profile");
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <>
    <MDBContainer className="center" >
    <div className="p-3 d-flex align-items-center justify-content-center">
      <div>
        <button
          type="button"
          className={style.googleSign}
          onClick={() => signup()}
        >
          <img src={require("../../logos/icons8-google-48.png")} alt="" />
          <span> Sign Up with Google</span>
        </button>
        <div className={style.OrSignin}>
          <span>Or </span>
          <Link to="/login"> Sign In</Link>
        </div>
        {error && <div className={style.error}>{error}</div>}
      </div>
    </div>
    </MDBContainer>
    </>
  );
};

export default Signup;
